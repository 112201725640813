'use strict';


angular.module('kljDigitalLibraryApp')

.controller('OrderCtrl', ["$scope", "$rootScope", "$state", "ngToast", "User", "OrderService", "SubscriptionService",
    function($scope, $rootScope, $state, ngToast, User,
        OrderService, SubscriptionService) {
        var init = function() {

            if ($state.params.subscription) {
                subscriptionDetails($state.params.subscription);
            }
            $rootScope.title = 'Order';
            $scope.couponApplied = false;


            var subscriptionId = $state.params.subscription;
            var apiBody = {
                code: undefined,
                subscriptionId: subscriptionId
            }
            getInitialOrderDetails(apiBody);
            getUsersAddress();

        }

        /**
          Get User's address
        */

        var getUsersAddress = function() {

            User.get().$promise.then(function(user) {
                $scope.userAddress = user.addresses[0] ? user.addresses[0].address + ", " + user.addresses[0].landmark + ", " + user.addresses[0].city + ", " + user.addresses[0].pincode : '';

            })

        }

        /**

        */

        var getInitialOrderDetails = function(body) {

            getPrice(body)

            .then(function(data) {
                $scope.order = data;
            })

            .catch(function(error) {

                ngToast.danger('Internal server error!');
            })
        }

        /**
		  Get subscription details from subscription id
  */

        var subscriptionDetails = function(id, status) {

            SubscriptionService.getSubscriptionDetails(id)

            .then(function(data) {
                $scope.planDetails = data;
                $scope.planDetails.type = status === true ? 'Renew' : 'Buy';
                $rootScope.title = data.title + " | " + data.plan_type + " | " + data.mode + " | Payement";
            })

            .catch(function(error) {

            });
        }

        /**
          Get price details of the subscription
        */

        var getPrice = function(body) {

            return OrderService.getPrice(body);
        }

        /**
          Apply coupon code
        */

        $scope.applyCode = function(code) {
            $scope.couponApplied = false;
            getPrice(getPriceApiBody(code))

            .then(function(coupon) {
                $scope.appliedCode = code;
                $scope.order = coupon;
                $scope.couponApplied = true;
                $scope.couponMsg = "";
                // ngToast.create('Coupon has been applied!');
            })

            .catch(function(error) {
                $scope.couponMsg = error.data;
            })
        }

        /**
          Remove coupon from purchase coupon
        */

        $scope.removeCoupon = function() {

                $scope.couponApplied = false;
                $scope.couponCode = undefined;
                getPrice(getPriceApiBody($scope.couponCode))

                .then(function(coupon) {
                    $scope.order = coupon;
                    // ngToast.create('Coupon has been removed!');
                })

                .catch(function(error) {
                    ngToast.danger('Error in removing coupon code!');
                })

            }
            /**
              Make Payment
            */

        var makePayment = function() {
            console.log("Payment");
        }

        /**
          Create Api body for getPrice api
        */

        var getPriceApiBody = function(code) {

            var body = {};
            body.subscriptionId = $scope.planDetails._id;
            body.code = code;
            return body;
        }

        /**
         Show Error for payment
         */
        $scope.$on('paymentError', function(event, msg) {
            console.log("paymentError", msg);
            $scope.paymentErrorMsg = msg;
        })


        /**
		Call init function of the controller
    */

        init();

    }
]);